<template>
  <div class="input-wrapper">
    <label>
      <slot>Checkbox label</slot>

      <input
        type="checkbox"
        :checked="value"
        @change="handleChange"
        class="form-check-input"
        :class="cssClass"
      />

      <span
        class="checkmark"
        :class="cssClass"
      ></span>

      <div class="invalid-feedback">
        {{ validationMessage }}
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    validationMessage: String,
    cssClass: String
  },
  methods: {
    handleChange (event) {
      this.$emit('input', event.target.checked)
    }
  }
}
</script>

<style scoped lang="scss">
  .input-wrapper {
    display: block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 12px;
    font-weight: 300;
    color: var(--blue-grey);

    label {
      margin-bottom: 0;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: -1px;
      left: 0;
      height: 19px;
      width: 19px;
      border: 1px solid var(--light-periwinkle-battleship-grey-three);
      border-radius: 2px;

      &.is-invalid {
        border-color: var(--light-peach);
      }
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid var(--tealish-blue-dark-sky-blue);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .invalid-feedback {
    position: absolute;
    margin-top: 2px;
    font-size: 11px;
    font-weight: 300;
    color: var(--dark-coral);
  }
</style>
