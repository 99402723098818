<template>
  <div>
    <form class="auth-form" @submit.prevent="login()">
      <base-logo-wave class="text-center auth-logo" />

      <h2>{{ $t('auth.login.title') }}</h2>

      <base-input
        v-model="loginData.email"
        type="email"
        :placeholder="$t('auth.login.email_placeholder')"
        required
        autofocus
      />

      <base-input
        v-model="loginData.password"
        type="password"
        :placeholder="$t('auth.login.password_placeholder')"
        required
      />

      <b-row>
        <b-col cols="6">
          <base-checkbox v-model="loginData.remember_me">
            {{ $t('auth.login.remember_me_checkbox') }}
          </base-checkbox>
        </b-col>
        <b-col  cols="6" class="text-right">
          <router-link :to="{ name: 'forgot-password' }">
            {{ $t('auth.login.forgot_password') }}
          </router-link>
        </b-col>
      </b-row>

      <base-button type="submit" class="btn-block btn-lg">
        {{ $t('auth.login.login_btn') }}
      </base-button>

      <p class="bottom-text text-center">
        {{ $t('auth.login.dont_have_an_account') }}
        &nbsp;<router-link :to="{ name: 'sign-up' }">{{ $t('auth.login.sign_up') }}</router-link>

        <br>

        {{ $t('auth.login.did_not_receive_confirmation_instructions') }}
        &nbsp;<router-link :to="{ name: 'resend-confirmation-instructions' }">{{ $t('auth.login.resend_confirmation_instructions') }}</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import BaseLogoWave from '../../components/BaseLogoWave.vue'
import BaseInput from '../../components/BaseInput.vue'
import BaseCheckbox from '../../components/BaseCheckbox.vue'
import BaseButton from '../../components/BaseButton.vue'

export default {
  data () {
    return {
      loginData: {
        email: '',
        password: '',
        remember_me: false
      }
    }
  },
  components: {
    BaseLogoWave,
    BaseInput,
    BaseCheckbox,
    BaseButton
  },
  mounted () {
    if (this.$route.query.account_confirmation_success === 'true') {
      this.$notify({
        type: 'success',
        text: this.$t('auth.confirmations.confirmed')
      })
    }
  },
  methods: {
    async login () {
      await this.$recaptchaLoaded()
      this.$Progress.start()

      this.$recaptcha('login').then(token => {
        this.$auth.login({
          data: this.loginData,
          fetchUser: true,
          staySignedIn: true
        }).then(() => {
          this.$auth.load().then(() => {
            if (this.$auth.check()) {
              this.$store.dispatch('profile/account/loadCurrentUser')
            }

            if (!this.$auth.user().profile_completed) {
              this.$router.push({ name: 'profile.account' })

              this.$notify({
                type: 'warn',
                text: this.$t('profile.account.update_your_account_data')
              })
            } else if (this.$auth.user().subscriptions.length > 0) {
              this.$router.push({ name: `${this.$auth.user().subscriptions[0].markets[0].slug}.instruments` })
            }
          })

          this.$Progress.finish()
        }, (error) => {
          this.$Progress.finish()
          this.loginData.password = ''

          this.$notify({
            type: 'error',
            text: this.devise().formatResponse(error.response.data) || this.$t('common.unknown_error')
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--cool-grey);
  margin-bottom: 20px;
  }
</style>
